import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from "react-router-dom";
import {request} from "../apis/http";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import moment from "moment";

const Detail = () => {
  const [searchParam] = useSearchParams();
  const id = searchParam.get('id');
  const fakeRef = useRef(null);
  const videoRef = useRef(null);
  const [detail, setDetail] = useState();
  const changeImg = () => {
    if (fakeRef.current.classList.contains('active')) {
      fakeRef.current.classList.remove('active');
      setTimeout(()=> {
        videoRef.current?.play();
      }, 500);
    }
  }


  const getData = async () => {
    try {
      const {data, status} = await request.get(`/board/detail/${id}`);
      if (status === 200) {
        setDetail(data.data);
      }
    } catch (e) {
      console.log('e:::', e);
    }
  }


  useEffect(() => {
    if( typeof window !== 'undefined') {
      window.scrollTo(0,0);
    }
    if (!detail) {
      getData();
    }

  }, [detail]);
  return (
    <div>
      {detail &&
        <div className="detail-page">
          <div className="detail-container">
            <div className="title">
              <h2>{detail.title}</h2>
              <p>{moment(detail.openDate).format('yyyy-MM-DD')}</p>
            </div>
            <div className="contents">
              <div className={detail.bgType}>
                {detail.videoUrls && <div className="thumb video">
                  <img ref={fakeRef} src={detail.fakeImg} className={'fake-youtube-img active'}
                       onClick={changeImg}/>
                  <video controls ref={videoRef}
                         onPause={(e) => {
                           fakeRef.current.classList.add('active');
                         }}
                         onTimeUpdate={(e) => {
                           if (fakeRef.current.classList.contains('active')) {
                             fakeRef.current.classList.remove('active')
                           }
                         }}
                         onClick={(e) => {
                           e.preventDefault();
                         }} width="100%">
                    <source src={detail.videoUrls} type="video/mp4"/>
                  </video>
                </div>}
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {detail.description}
                </ReactMarkdown>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default Detail;
