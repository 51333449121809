import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import React, {useRef} from 'react';
import Slider from 'react-slick';

const DetailModal = ({setOpen, detail}) => {
  const slickRef = useRef(null);

  const settings = {
    dots: false,
    centerMode: true,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerPadding:0,
  };
  return (
    <div className={'modal-wrapper'}>
      <div className="case">
        <div className="img-thumb">
          <div className="close" onClick={setOpen}>
            <span/>
            <span/>
          </div>
          <div className="prev-arr" onClick={()=> { slickRef.current.slickPrev() }}>PREVIOUS</div>
          <div className="next-arr" onClick={()=> { slickRef.current.slickNext() }}>NEXT</div>
          <Slider {...settings} ref={slickRef}>
            {detail.map((item, idx) => (
              <img src={item.cardImg} alt={`img-id-${idx}`} key={`img-id-${idx}`}/>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  )
}

export default DetailModal;
