import React from 'react';

const Policy1 = () => {
  return(
    <div className="policy">
      <h2>서비스 이용 약관 (필수)</h2>
      <h4>제 1 장 총 칙</h4>
      <div>
        <h5>제1조 (목 적)</h5>
        <p>이 약관은 회원이 주식회사 경신홀딩스(이하 “회사”라 한다)가 ‘경신그룹 50주년 앱’(이하 “앱”이라 한다)을 통해 제공하는 서비스(이하 “서비스”라 한다)를 이용함에 있어서 회원과 회사
          간의 권리, 의무 및 책임사항 기타 필요한 사항에 대하여 규정함을 목적으로 합니다.</p>
      </div>
      <div>
        <h5>제2조 (용어의 정의)</h5>
        <ul>
          <li>
            ① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
            <ul>
              <li>1. 회원: 주식회사 경신홀딩스 및 그 자회사의 임직원으로서 앱에 접속하여 이 약관에 동의하고, 회사 또는 운영자로부터 아이디와 비밀번호를 부여받은 자</li>
              <li>2. 아이디: 회원 식별과 회원의 서비스 이용을 위하여 회원이 선정하고 회사가 승인하는 ID</li>
              <li>3. 비밀번호: 회원의 정보 보호를 위해 회원 자신이 설정한 문자와 숫자의 조합</li>
              <li>4. 운영자: 회사가 서비스의 전반적인 관리와 원활한 운영을 위하여 선정한 자</li>
              <li>5. 서비스 일시중지: 정상 이용 중 회사가 정한 일정한 요건에 따라 일정기간 동안 서비스의 제공을 중지하는 것</li>
            </ul>
          </li>
          <li>② 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계 법령 및 서비스별 안내에서 정하는 바에 의합니다.</li>
        </ul>
      </div>
      <div>
        <h5>제3조 (약관의 효력 및 변경)</h5>
        <ul>
          <li>① 이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공시하고, 이에 동의한 회원이 서비스에 가입함으로써 효력을 발생합니다.</li>
          <li>② 회사는 합리적인 사유가 발생할 경우 관계 법령에 위배되지 않는 범위 내에서 본 약관을 변경할 수 있으며, 개정된 약관은 사이트에 공지함으로써 효력이 발생합니다. 다만, 회원의 권리 또는
            의무에 관한 중요한 규정의 변경은 최소한 7일전에 공시합니다.
          </li>
          <li>③ 회원은 변경된 약관 사항에 동의하지 않으면 회원 탈퇴(해지)를 요청할 수 있습니다.</li>
        </ul>
      </div>
      <div>
        <h5>제4조 (약관 외 준칙)</h5>
        <p>이 약관에 명시되지 않은 사항에 대해서는 「전기통신기본법」, 「전기통신사업법」 등 관계 법령과 상관습에 따릅니다.</p>
      </div>
      <h4>제 2 장 서비스 이용 계약</h4>
      <div>
        <h5>제5조 (서비스 이용 계약의 성립)</h5>
        <ul>
          <li>① 서비스 이용 계약은 서비스를 이용하고자 하는 자가 제6조에 따라 회원가입을 신청하고 이 약관에 동의를 완료한 경우에 성립됩니다.</li>
          <li>② 서비스를 이용하려는 자가 서비스에 가입하는 절차(이하 “회원가입”이라 한다)에서 "위의 이용약관에 동의하십니까?" 라는 항목에 "동의" 버튼을 누르면 이 약관에 동의하는 것으로
            간주됩니다.
          </li>
          <li>③ 서비스를 이용하고자 하는 자는 제8조에 따라 회사가 요청하는 개인정보를 제공해야 합니다.</li>
        </ul>
      </div>
      <div>
        <h5>제6조 (회원가입)</h5>
        <p>회원가입은 서비스의 회원 등록 화면에서 회원이 다음 각 호의 사항을 회원가입 양식에 기록하는 방식으로 행합니다.</p>
        <ul>
          <li>1. 근무 회사명</li>
          <li>2. 이름</li>
          <li>3. 아이디</li>
          <li>4. 사번</li>
          <li>5. 이메일</li>
          <li>6. 연락처</li>
          <li>7. 비밀번호</li>
          <li>8. 기타 회사가 필요하다고 인정하는 사항</li>
        </ul>
      </div>
      <div>
        <h5>제7조 (회원가입의 승낙)</h5>
        <p>다음 어느 각 호에 해당하는 경우에 대하여는 회사가 회원가입을 승낙하지 않거나, 승낙을 취소할 수 있습니다.</p>
        <ul>
          <li>1. 기술상 서비스 제공이 불가능한 경우</li>
          <li>2. 실명이 아니거나, 다른 사람의 명의사용 등 이용자 등록 시 허위로 신청하는 경우</li>
          <li>3. 제6조 각 호 중 일부를 누락하거나 오기하여 신청하는 경우</li>
          <li>4. 기타 관계 법령 등을 기준으로 사회의 안녕과 질서 또는 미풍양속을 저해할 우려가 있다고 인정되는 경우</li>
          <li>5. 제13조에 의하여 이전에 회원 자격을 상실한 적이 있는 경우 (다만, 동 자격 상실 이후 1년 이상 경과한 자로 회사의 회원 재가입 승낙을 받은 경우는 예외로 합니다.)</li>
          <li>6. 기타 회사가 정한 이용신청 요건이 만족되지 않았을 경우</li>
        </ul>
      </div>
      <h4>제 3 장 개인정보 관리</h4>
      <div>
        <h5>제8 조 (개인정보의 수집∙이용)</h5>
        <ul>
          <li>① 회사는 회원의 개인정보 수집 시 서비스 제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.</li>
          <li>② 회사는 수집된 개인정보를 목적 외의 용도로 이용할 수 없으며, 새로운 이용 목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 회원에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련
            법령에 달리 정함이 있는 경우에는 예외로 합니다.
          </li>
          <li>③ 회원은 언제든지 회사가 가지고 있는 자신의 개인정보에 대해 열람 및 오류 정정을 요구할 수 있으며 회사는 이에 대해 필요한 조치를 취합니다.</li>
          <li>④ 회사는 제1항의 개인정보 수집∙이용 목적 범위 내에서 제3자에게 개인정보 처리 업무를 위탁할 수 있으며, 위탁하는 경우에는 위탁 받는 자 및 위탁하는 개인정보 처리 업무의 내용 등을
            회원에게 고지합니다.
          </li>
        </ul>
      </div>
      <div>
        <h5>제9 조 (개인정보의 보호)</h5>
        <ul>
          <li>① 회사는 회원의 개인정보를 보호하기 위하여 관계 법령에서 규정한 바를 준수합니다.</li>
          <li>② 기타 이 약관에서 정하지 않은 사항은 회사가 마련한 「개인정보 처리방침」에 따릅니다.</li>
        </ul>
      </div>
      <h4>제 4 장 서비스 이용</h4>
      <div>
        <h5>제10 조 (서비스의 이용시간)</h5>
        <ul>
          <li>③ 서비스의 이용은 사이트의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일 24시간을 원칙으로 합니다.</li>
          <li>④ 전항의 서비스 이용시간은 시스템 정기점검 등 회사가 필요한 경우 회원에게 사전 통지한 후 제한할 수 있습니다. 다만, 회사가 통제할 수 없는 사유로 인해 서비스가 중단되는 경우에는 사전
            통지 없이 서비스의 이용이 제한될 수 있습니다.
          </li>
          <li>⑤ 회사는 서비스를 일정 범위로 분할하여 각 범위별로 이용 가능한 시간을 별도로 정할 수 있으며 이 경우 그 내용을 공지합니다.</li>
        </ul>
      </div>
      <div>
        <h5>제11 조 (게시물 또는 내용물의 삭제)</h5>
        <ul>
          <li>① 회원이 게시하거나 전달하는 서비스 내의 모든 내용물(회원간 전달 포함)이 다음 어느 각 호에 해당한다고 판단되는 경우 사전통지 없이 삭제될 수 있으며, 이에 대해 회사는 어떠한 책임도
            지지 않습니다.
            <ul>
              <li>1. 회사, 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우</li>
              <li>2. 공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등의 유포에 해당하는 경우</li>
              <li>3. 범죄적 행위에 결부된다고 인정되는 내용인 경우</li>
              <li>4. 회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</li>
              <li>5. 제2항 소정의 세부이용지침을 통하여 회사에서 규정한 게시기간을 초과한 경우</li>
              <li>6. 회사에서 제공하는 서비스와 관련 없는 내용인 경우</li>
              <li>7. 불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우</li>
              <li>8. 기타 관계 법령 및 사이트의 지침 등에 위반된다고 판단되는 경우</li>
            </ul>
          </li>
          <li>② 회사는 게시물에 관련된 세부 이용지침을 별도로 정하여 시행할 수 있으며, 회원은 그 지침에 따라 각 종 게시물(회원간 전달 포함)을 등록하거나 삭제하여야 합니다.</li>
        </ul>
      </div>
      <div>
        <h5>
          제12조 (게시물의 저작권)
        </h5>
        <ul>
          <li>① 회원이 서비스 내에 게시한 게시물(회원간 전달 포함)의 저작권은 회원이 소유하며 회사는 서비스 내에 이를 게시할 수 있는 권리를 갖습니다.</li>
          <li>② 회사는 게시한 회원의 동의 없이 게시물을 다른 목적으로 사용할 수 없습니다.</li>
          <li>③ 회사는 회원이 서비스 내에 게시한 게시물이 타인의 저작권, 프로그램 저작권 등을 침해하더라도 이에 대한 민∙형사상의 책임을 부담하지 않습니다. 만일 회원이 타인의 저작권, 프로그램저작권
            등을 침해하였음을 이유로 회사가 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은 회사의 면책을 위하여 노력하여야 하며, 회사가 면책되지 못한 경우 회원은 그로 인해 회사에 발생한 모든
            손해를 부담하여야 합니다.
          </li>
          <li>④ 회사는 서비스 이용 계약이 제13조 또는 제14조에 의하여 해지된 경우 해당 회원이 게시하였던 게시물을 삭제할 수 있습니다.</li>
          <li>⑤ 회사 또는 운영자가 작성한 저작물에 대한 저작권은 회사에 귀속합니다.</li>
        </ul>
      </div>
      <h4>제 5 장 서비스 이용 제한 및 계약의 해지</h4>
      <div>
        <h5>제13조 (서비스 이용 제한)</h5>
        <ul>
          <li>
            ① 회사는 회원이 다음 어느 각 호에 해당하는 행위를 하였을 경우, 사전 통지 없이 이용계약을 해지하거나, 기간을 정하여 그 회원의 서비스 이용을 제한할 수 있습니다.
            <ul>
              <li>1. 공공 질서 및 미풍 양속에 저해되는 행위를 한 경우</li>
              <li>2. 범죄적 행위에 관련되는 경우</li>
              <li>3. 국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우</li>
              <li>4. 타인의 사번 또는 명의를 도용하여 회원가입한 경우</li>
              <li>5. 타인의 아이디 및 비밀번호를 도용한 경우</li>
              <li>6. 타인의 명예를 손상시키거나 불이익을 주는 경우</li>
              <li>7. 회원이 1개 이상의 아이디로 이중 등록을 한 경우</li>
              <li>8. 저작권 등 기타 타인의 권리를 침해하는 내용인 경우</li>
              <li>9. 해킹, 바이러스 유포 등 서비스에 위해를 가하여 서비스의 건전한 이용을 저해하는 경우</li>
              <li>10. 기타 관련 법령이나 회사가 정한 서비스 이용조건에 위배되는 경우</li>
            </ul>
          </li>
          <li>② 회사가 회원의 이용계약을 해지하거나 서비스 일시중지할 필요가 있다고 판단한 경우 상당한 기간 전에 이를 회원에게 통지합니다. 다만, 긴급하게 서비스 이용을 중지해야 할 필요가 있는
            경우에는 회사는 사전 통지 없이 서비스를 중지할 수 있습니다.
          </li>
          <li>③ 회사로부터 서비스 일시중지를 통지받은 회원은 그 서비스 일시 중지에 대하여 이의신청을 할 수 있습니다.</li>
          <li>④ 회사는 서비스 일시중지 기간 중에 그 사유가 해소된 것이 확인되면, 회원의 서비스 일시중지를 즉시 해제합니다.</li>
        </ul>
      </div>
      <div>
        <h5>제14조 (계약의 해지)</h5>
        <ul>
          <li>① 회원이 서비스 이용계약을 해지하고자 할 경우에는 서비스 화면 상에서 또는 회사가 정한 별도의 이용방법으로 서비스 이용에 관한 해지신청을 하여야 합니다.</li>
          <li>② 전항의 경우 회원이 이름, ID, 비밀번호를 입력하여 본인임을 확인한 후에 회원탈퇴 확인을 클릭하면 자동으로 회원가입 및 서비스 이용계약이 해지됩니다.</li>
        </ul>
      </div>
      <h4>제 6 장 손해배상 등</h4>
      <div>
        <h5>제15조 (손해 배상)</h5>
        <ul>
          <li>① 회원이 이 약관의 규정을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우, 이 약관을 위반한 회원은 회사에 발생하는 모든 손해를 배상하여야 합니다.</li>
          <li>② 회원이 서비스를 이용함에 있어 행한 불법행위나 이 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은
            자신의 책임과
            비용으로 회사를 면책시켜야 하며, 사이트가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.
          </li>
        </ul>
      </div>
      <div>
        <h5>제16조 (면책 조항)</h5>
        <ul>
          <li>① 회사는 회원이 서비스에 게재한 정보, 자료, 사실의 정확성, 신뢰성 등 내용에 관하여는 어떠한 책임도 지지 않습니다.</li>
          <li>② 회사는 회원이 서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것에 대하여 책임을 지지 않으며, 회원이 서비스를 이용하면서 얻은 자료로 인한 손해에 대하여 책임을 지지
            않습니다.
          </li>
          <li>③ 회사는 회원 상호 간 또는 회원과 제3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며, 이로 인한 손해를 배상할 책임도 없습니다.</li>
          <li>④ 회사는 회원의 귀책사유로 인하여 서비스 이용의 장애가 발생한 경우에는 책임이 면제됩니다.</li>
          <li>⑤ 회사는 천재지변 또는 이에 준하는 통제할 수 없는 사유로 인해 서비스를 제공할 수 없는 경우에는 서비스 제공에 대한 책임이 면제됩니다.</li>
        </ul>
      </div>
      <div>
        <h5>제17조 (관할 법원)</h5>
        <ul>
          <li>① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의합니다.</li>
          <li>② 전항에도 불구하고 회사와 회원 간의 분쟁이 해결되지 않을 경우, 양 당사자는 민사소송법상의 관할법원에 소를 제기할 수 있습니다.</li>
        </ul>
      </div>
      <p>
        (부칙) 이 약관은 2024년 03월 11일부터 시행합니다.<br/><br/>
        위의 이용약관에 동의합니다.
      </p>
    </div>
  )
}

export default Policy1;
