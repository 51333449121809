import React, {useEffect, useRef} from 'react';
import gsap from 'gsap';
import {Observer} from 'gsap/Observer';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {ScrollToPlugin} from 'gsap/ScrollToPlugin';

gsap.registerPlugin(Observer, ScrollTrigger, ScrollToPlugin);

const Vision = () => {
  const containerRef = useRef();
  const swiperRef = useRef();
  let tab1Scroll = 0;
  let tab2Scroll = 0;
  let tab3Scroll = 0;
  let tab4Scroll = 0;
  let tab5Scroll = 0;

  const changeActive = (value) => {
    const tabs = document.querySelectorAll('.tabs');
    const list = document.querySelectorAll('.chronicle-list ul li');
    tabs.forEach((menu) => {
      menu.classList.remove('active');
    })
    const getId = document.querySelector( `#tab${value}`);
    tabs[value-1].classList.add('active');

    list.forEach((item) => {
      item.classList.remove('active');
    })

    getId.classList.add('active');

      if(value === 1) {
        gsap.fromTo(window, { scrollTo: window.scrollY }, { duration: 1, scrollTo: {y: tab1Scroll > 0 ? tab1Scroll : 4200}, ease : "expo.easeInOut"})
      }
      if(value ===2) {
        gsap.fromTo(window, { scrollTo: window.scrollY }, { duration: 1, scrollTo: {y: tab2Scroll > 0 ? tab2Scroll : tab1Scroll + (100 * 6) }, ease : "expo.easeInOut"})
      }
      if(value ===3) {
        gsap.fromTo(window, { scrollTo: window.scrollY }, { duration: 1, scrollTo: {y: tab3Scroll > 0 ? tab3Scroll : tab1Scroll+ (100 * 23)}, ease : "expo.easeInOut"})
      }
      if(value ===4) {
        gsap.fromTo(window, { scrollTo: window.scrollY }, { duration: 1, scrollTo: {y: tab4Scroll > 0? tab4Scroll:  tab1Scroll+ (100 * 45)}, ease : "expo.easeInOut"})
      }
      if(value ===5) {
        gsap.fromTo(window, { scrollTo: window.scrollY }, { duration: 1, scrollTo: {y: tab5Scroll > 0? tab5Scroll : tab1Scroll+ (100 * 60)}, ease : "expo.easeInOut"})
    }
  }

  useEffect(() => {
    console.log("window..", window.scrollY);
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (!swiperRef.current) return ;
    let ctx = gsap.context(() => {
      const container = document.querySelector('.list-ui');

      let mm = gsap.matchMedia();

      mm.add('(max-width:475px)', () => {

      });
      mm.add('(max-width:768px)', () => {

      })
      mm.add('(max-width:1024px)', () => {

      })
      mm.add('(max-width:1440px)', () => {

      })

      mm.revert();


      const timeList = gsap.timeline({
        scrollTrigger: {
          trigger: swiperRef.current,
          pin: true,
          scrub: true,
          ease: 'none',
          end: '+=3000',
          invalidateOnRefresh: true,
          defaults: { ease: "none", duration: 1 }
        }
      })

      timeList.set('.vision', { paddingTop: '180px', height: '100vh'});
      timeList.addLabel('remove-title')
      timeList.to(container, {xPercent: -45, duration: 50},'remove-title');
      timeList.fromTo('.back-text.start', { autoAlpha: 1 },{ autoAlpha: 0, duration: 10 },'remove-title');
      timeList.fromTo('.back-text.end', { autoAlpha: 0 }, { autoAlpha: 1, duration: 20, delay: 20 }, '>');

      const menuList = gsap.utils.toArray('.chronicle-list li');
      let ti2 = gsap.timeline({
        paused: true,
        scrollTrigger: {
          trigger: '.fixed-text',
          markers: false,
          scrub: true,
          pin: true,
          start: 'top top',
          end: 'bottom bottom',
          endTrigger: '.flexible',
        }
      });
      ti2.set('.history', {paddingTop: '120px' });
      ti2.set(menuList[0],{ className: "active" });
      ti2.set('.fixed-text',{ marginTop: '120px' });
      menuList.forEach((menu, index) => {

        gsap.to(menu, {
          scrollTrigger: {
            id: `menu-${index}`,
            toggleClass: 'active',
            trigger: menu,
            markers: false,
            scrub: true,
            pin: false,
            start: 'top center',
            end: 'bottom center',
            onEnter: (self) => {
              if (self.trigger.id) {
                if(self.trigger.id === 'tab1') {
                  tab1Scroll = window.scrollY;
                }
                if(self.trigger.id === 'tab2') {
                  tab2Scroll = window.scrollY;
                }
                if(self.trigger.id === 'tab3') {
                  tab3Scroll = window.scrollY;
                }
                if(self.trigger.id === 'tab4') {
                  tab4Scroll = window.scrollY;
                }
                if(self.trigger.id === 'tab5') {
                  tab5Scroll = window.scrollY;
                }
              }
            },
            onLeave: (self) => {
              if (self.trigger.id) {
                // if(self.trigger.id === 'tab1') {
                //   tab1Scroll = window.scrollY;
                // }
                // if(self.trigger.id === 'tab2') {
                //   tab2Scroll = window.scrollY;
                // }
                // if(self.trigger.id === 'tab3') {
                //   tab3Scroll = window.scrollY;
                // }
                // if(self.trigger.id === 'tab4') {
                //   tab4Scroll = window.scrollY;
                // }
                // if(self.trigger.id === 'tab5') {
                //   tab5Scroll = window.scrollY;
                // }
              }
            },
            onToggle: (self) => {
              if (self.trigger.id) {
                if(index !== menuList.length - 1) {
                  document.querySelectorAll('.tabs').forEach((item) => {
                    item.classList.remove('active');
                  })
                }
                document.querySelector(`.${self.trigger.id}`).classList.add('active');
              }
            }
          }
        })
      })
    }, containerRef.current);

    return () => {
      ctx.revert()
    }
  }, []);

  return (
    <div ref={containerRef}>
      <section className={'section vision test'} ref={swiperRef}>
        <div className="screen"/>
        <div className="vision-adv">
          <div className="back-text start">
            <h3>
              Dear<br/>
              Kyungshiners,
            </h3>
            <h6>경신 그룹 50주년, 이 찬란한 오늘에 함께하는 당신에게.</h6>
            <p>
              50년 경신의 가장 큰 자부심인 당신에게 무한한 감사를 보내며, <br/>
              또 한번 멋진 하모니로 만들어갈 경신의 미래를 그립니다. <br/>
              앞으로도 여러분과 함께할 근사한 모험을 기대하겠습니다.
            </p>
          </div>
          <ul className={'list-ui'}>
            <li>
              <a className="item">
                <div className="thumb">
                  <img src="/images/vision01.png"/>
                </div>
                <p>
                  경신의 역사를 일군 주인공은 당신과 나, 우리 모두입니다. 경신의 역사는 당신의 자부심이며, 그 역사를 만든 당신이 곧 경신의 자부심임을 슬로건에 담았습니다.
                </p>
                <p>
                  ‘도전과 개척'을 상징하는 경신의 주조색인 파란색과 ‘나눔과 동반성장 ’을 상징하는 초록색을 교차해 활용한 이 엠블럼은 사람, 기술, 환경의 균형을 추구하며
                  ‘조화롭게’, 그리고 ‘함께’ 걸어온 경신의 50년을 상징합니다.
                </p>
              </a>
            </li>
            <li>
              <a className="item">
                <div className="thumb">
                  <img src="/images/vision02.png"/>
                </div>
                <p>
                  전선의 꼬임을 형상화한 50주년 기념 로고는 경신의 대표 사업분야인 '와이어링 하네스'를 은유합니다.
                </p>
                <p>
                  경신의 아이덴티티를 극대화해 표현한 이 엠블럼은 복잡한 전선들이 한곳에 모여 하나의 커다란 가치를 만들듯, ‘조화’를 통해 ‘함께’ 걸어온 경신의 50년 역사와,
                  앞으로 나아갈 미래에 대한 끝없는 여정을 상징합니다.
                </p>
              </a>
            </li>
            <li>
              <a className="item">
                <div className="thumb">
                  <img src="/images/vision03.png"/>
                </div>
                <p>
                  50주년을 맞이하는 경신의 세 가지 핵심가치는 자부심(PRIDE), 모두 함께(TOGETHER), 하나된 경신(HARMONY)로 집약됩니다.
                  이 세 가지 핵심가치는 어느 하나에 치중되지 않고 끝없이 조화롭게 연결됩니다.
                </p>
                <p>
                  경신의 역사를 만든 주인공이자, 경신의 미래를 열어갈 ‘경신의 자부심‘은 바로 당신이며,
                  경신의 자부심이 모두 함께 하나된 경신을 만들어 갈 때
                  비로소 100년을 향한 경신의 도전과 개척이 이루어질 수 있다는 메시지를 담았습니다.
                </p>
              </a>
            </li>
          </ul>
          <div className="back-text end">
            <h3>
              Pride<br/>
              Together<br/>
              Harmony<br/>
              With you,<br/>
              With Kyungshin.
            </h3>
          </div>
        </div>
      </section>

      <section className="section history test">
        <div className="container">
          <div className="flexible">
            <div className="fixed-text">
              <h3>함께 걸어온 경신의 길</h3>
              <p>우리의 기억은 우리의 자부심입니다.</p>
              <ul className={'nav-list'}>
                <li className="tab1 active tabs">
                  <a className="item" onClick={() => changeActive(1)}>
                    <label>1974 ~ 1984</label>
                    <p>창업과 자동차 부품산업 진출</p>
                  </a>
                </li>
                <li className={'tab2 tabs'}>
                  <a className="item" onClick={() => changeActive(2)}>
                    <label>1985 ~ 1999</label>
                    <p>위기를 극복하고 성장 기반을 구축하다</p>
                  </a>
                </li>
                <li className={'tab3 tabs'}>
                  <a className="item" onClick={() => changeActive(3)}>
                    <label>2000 ~ 2009</label>
                    <p>우수한 품질과 기술력을 바탕으로 세계로 뻗어 나가다</p>
                  </a>
                </li>
                <li className={'tab4 tabs'}>
                  <a className="item" onClick={() => changeActive(4)}>
                    <label>2010 ~ 2015</label>
                    <p>사업을 다각화하며 글로벌 자동차부품업체로 도약하다</p>
                  </a>
                </li>
                <li className={'tab5 tabs'}>
                  <a className="item" onClick={() => changeActive(5)}>
                    <label>2016 ~ 2023</label>
                    <p>미래 자동차 부품산업을 선도하는 <br/>글로벌 자동차 전장부품 기업을 향하여</p>
                  </a>
                </li>
              </ul>
            </div>
            <div className="chronicle-list">
              <ul>
                <li className="active" id={'tab1'}>
                  <p>산업보국의 창업정신으로 경신의 모태, 경신공업 설립(창업주 이기홍 사장)</p>
                  <span>1974</span>
                </li>
                <li>
                  <p>국내 최초의 국산차 포니에 와이어링 하네스(Wiring Harness) 공급</p>
                  <span>1974</span>
                </li>
                <li>
                  <p>자동차 부품 전문공장 지정</p>
                  <span>1978</span>
                </li>
                <li>
                  <p>석탑 산업훈장 수상</p>
                  <span>1984</span>
                </li>
                <li>
                  <p>경주공장 준공</p>
                  <span>1984</span>
                </li>
                <li id={'tab2'}>
                  <p>새로운 리더십의 탄생, 김현숙 대표이사 취임</p>
                  <span>1985</span>
                </li>
                <li>
                  <p>100만 불 수출탑 수상</p>
                  <span>1985</span>
                </li>
                <li>
                  <p>중견수출기업 선정</p>
                  <span>1986</span>
                </li>
                <li>
                  <p>경신 강동공장 준공</p>
                  <span>1988</span>
                </li>
                <li>
                  <p>전선을 전문으로 생산하는 아산공장 준공</p>
                  <span>1989</span>
                </li>
                <li>
                  <p>경신 인도 마더슨사 합작 KIML 설립</p>
                  <span>1997</span>
                </li>
                <li>
                  <p>경신 품질 100PPM 달성 대통령상 수상</p>
                  <span>1998</span>
                </li>
                <li>
                  <p>경신 R&D의 산실 중앙기술연구소 설립</p>
                  <span>1999</span>
                </li>
                <li id={'tab3'}>
                  <p>경신 화성공장 준공</p>
                  <span>2000</span>
                </li>
                <li>
                  <p>경신전선 기업분리를 통한 책임경영 체제 확립</p>
                  <span>2000</span>
                </li>
                <li>
                  <p>경신 은탑산업훈장 수훈</p>
                  <span>2001</span>
                </li>
                <li>
                  <p>청도경신전자 유한공사 설립</p>
                  <span>2002</span>
                </li>
                <li>
                  <p>미국에 LEAR사와 JVC 생산 및 판매법인 설립하고 북미 진출</p>
                  <span>2003</span>
                </li>
                <li>
                  <p>경신 군산공장 준공</p>
                  <span>2003</span>
                </li>
                <li>
                  <p>경신 수출 1억불 탑 수상</p>
                  <span>2003</span>
                </li>
                <li>
                  <p>경신 일본 스미토모그룹과 기술 협력 및 자본합작</p>
                  <span>2004</span>
                </li>
                <li>
                  <p>인천 송도 신사옥 및 R&D센터 준공</p>
                  <span>2005</span>
                </li>
                <li>
                  <p>중국에 또 하나의 해외법인 중국 강소경신전자 설립</p>
                  <span>2005</span>
                </li>
                <li>
                  <p>강소경신전자 유한공사 설립</p>
                  <span>2007</span>
                </li>
                <li>
                  <p>이승관 대표이사 취임</p>
                  <span>2008</span>
                </li>
                <li>
                  <p>경신 금탑산업훈장 수훈</p>
                  <span>2009</span>
                </li>
                <li>
                  <p>경신전선 멕시코 공장 설립</p>
                  <span>2009</span>
                </li>
                <li id={'tab4'}>
                  <p>경신 제2의 창업 선언 - 사명 개정 및 신 CI 선포</p>
                  <span>2010</span>
                </li>
                <li>
                  <p>경신 커넥터사업 개시 및 자동차 부품생산 다각화</p>
                  <span>2010</span>
                </li>
                <li>
                  <p>경신 중국 청도즉묵경신전자 설립</p>
                  <span>2010</span>
                </li>
                <li>
                  <p>경신 국가생산성대상 지식경제부장관상 수상</p>
                  <span>2011</span>
                </li>
                <li>
                  <p>경신전선 천안공장으로 이전</p>
                  <span>2011</span>
                </li>
                <li>
                  <p>경신 캄보디아 해외 생산공장 준공</p>
                  <span>2012</span>
                </li>
                <li>
                  <p>경신 인천 송도에 부품 전문공장 설립</p>
                  <span>2013</span>
                </li>
                <li>
                  <p>경신전선 미국경신전선인터내셔널 설립</p>
                  <span>2013</span>
                </li>
                <li>
                  <p>경신 국가생산성대상 대통령상 수상</p>
                  <span>2013</span>
                </li>
                <li>
                  <p>안휘경신전자 설립</p>
                  <span>2015</span>
                </li>
                <li>
                  <p>경신 수출 9억불 탑 수상</p>
                  <span>2015</span>
                </li>
                <li id={'tab5'}>
                  <p>지주사 전환 경신홀딩스 설립</p>
                  <span>2016</span>
                </li>
                <li>
                  <p>경신전선 중국 창저우경신전선 설립</p>
                  <span>2017</span>
                </li>
                <li>
                  <p>경신 중국 연구소 설립</p>
                  <span>2017</span>
                </li>
                <li>
                  <p>KiB 클러스터 준공</p>
                  <span>2017</span>
                </li>
                <li>
                  <p>경신홀딩스 TSA 인수로 자동차 시트 전장부문 진출 </p>
                  <span>2018</span>
                </li>
                <li>
                  <p>경신전선 폴란드 설립</p>
                  <span>2018</span>
                </li>
                <li>
                  <p>경신 베트남 법인 설립</p>
                  <span>2019</span>
                </li>
                <li>
                  <p>경신전선 포드에 배터리팩 전장품 공급, 제품 및 거래선 다변화 실현</p>
                  <span>2019</span>
                </li>
                <li>
                  <p>경신 인도네시아 달마사와 DKI 합작법인 설립 </p>
                  <span>2020</span>
                </li>
                <li>
                  <p>경신전선 유럽(세르비아) 법인 설립</p>
                  <span>2020</span>
                </li>
                <li>
                  <p>경신전선 유럽(튀르키예) 판매법인 설립</p>
                  <span>2021</span>
                </li>
                <li>
                  <p>경신전선 멕시코 고메즈 팔라시오 공장 가동</p>
                  <span>2021</span>
                </li>
                <li>
                  <p>창사 50주년, 백년기업을 향해 전진하다</p>
                  <span>2024</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Vision;
