import React from 'react';

const Policy3 = () => {
  return(
    <div className="policy">
        <h2>개인정보 수집∙이용 동의 (필수)</h2>
        <div>
            <h5>1. 개인정보 수집∙이용</h5>
          <table>
            <colgroup>
              <col width={'30%'}/>
              <col width={'70%'}/>
            </colgroup>
            <tr>
              <th>수집∙이용하려는 항목</th>
              <td>이름, 아이디, 이메일, 연락처, 사번</td>
            </tr>
            <tr>
              <th>수집∙이용 목적</th>
              <td>
                1) 본 서비스 제공 및 관리<br/>
                2) 회원 관리<br/>
                3) 이벤트 진행 및 경품 지급<br/>
                4) 상기 1) 내지 3)에 수반한 업무
              </td>
            </tr>
            <tr>
              <th>보유 및 이용기간</th>
              <td>앱 운영 종료 시</td>
            </tr>
          </table>
        </div>
      <div>
        <h5>2. 개인정보 처리업무 위탁</h5>
        <table>
          <colgroup>
            <col width={'30%'}/>
            <col width={'70%'}/>
          </colgroup>
          <tr>
            <th>운영자</th>
            <td>주식회사 시하기획</td>
          </tr>
          <tr>
            <th>위탁한 개인정보 처리 업무</th>
            <td>
              1) 본 서비스 제공 및 관리<br/>
              2) 이벤트 진행 및 경품 지급<br/>
              3) 상기 1) 내지 2)에 수반한 업무
            </td>
          </tr>
          <tr>
            <th>보유 및 이용기간</th>
            <td>위탁 계약 종료 시</td>
          </tr>
        </table>
      </div>
      <p>동의를 거부할 수 있으나 동의 거부 시 회원가입이 제한됩니다.</p>
    </div>
  )
}

export default Policy3;
