import React from "react";

const DevDetail = () => {

  return (
    <div>
      <div className="detail-page">
        <div className="detail-container">
          <div className="title">
            <h2>경신 50주년 모바일 앱 오픈!</h2>
            <p>2024. 03. 18</p>
          </div>
          <div className="contents">
            <div className="thumbnails">
              <div className="img">
                <img src="/images/app_thumb2.png"/>
              </div>
              <div className="inner-wrapper">
                <br/>
                <br/>
                <br/>
                <p style={{textAlign: 'center'}}>
                  경신그룹의 50주년을 기념하여, <br/>
                  임직원 여러분들과의 소통을 위한 모바일 앱이 <br/>
                  3월 19일 오전 9시에 오픈됩니다!
                </p>
                <p style={{textAlign: 'center'}}>
                  경신 50주년 앱에서는 <br/>
                  50주년 기념 이벤트 참여 및 이벤트 진행 상황 확인, <br/>
                  그리고 각종 공지 사항 확인이 가능해요.
                </p>
                <p style={{textAlign: 'center'}}>
                  다양한 정보가 가득한 경신 50주년 기념 모바일 앱을 다운로드하고, <br/>
                  가장 먼저 경신의 소식을 확인해 보세요!
                </p>
                <p style={{textAlign: 'center'}}>
                  뒤이어 예정된 1차 업데이트에서는 출석 체크, <br/>
                  걷기 이벤트 활동 등을 통해 포인트 쌓아서 <br/>
                  다양한 리워드도 제공드릴 예정입니다!
                </p>
                <p style={{textAlign: 'center'}}>
                  즐거운 경신의 50주년을 함께 만들어가요!
                </p>
                <p style={{textAlign: 'center'}}><b>* 앱 1차 업데이트 일정: 2024.04.30</b></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DevDetail;
