import React from 'react';

const Policy4 = () => {
  return (
    <div className="policy">
        <h2>개인정보 수집∙이용 동의 (필수)</h2>
        <div>
            <p>
                본인은 회사 또는 회사가 지정한 이벤트(이하 “본 이벤트”라고 한다) 업무를 수행할 자(이하 “이벤트 수행자”라 한다)가 아래 목적ㆍ항목ㆍ기간의 범위에서 본인의 개인정보를 수집하여
                이용함에 있어, 동의를 거부할 권리가 있다는 사실과 동의를 거부하는 경우 본 이벤트 및 혜택의 대상에서 제외되는 등의 불이익이 발생할 수 있다는 사실을 안내받았으며, 그 수집ㆍ이용에
                동의합니다.
            </p>
          <table>
            <colgroup>
              <col width={'30%'}/>
              <col width={'70%'}/>
            </colgroup>
            <tr>
            <th>회사</th>
              <td>주식회사 경신홀딩스</td>
            </tr>
            <tr>
              <th>이벤트 수행자</th>
              <td>시하기획</td>
            </tr>
            <tr>
              <th>수집ㆍ이용하려는 개인정보의 항목</th>
              <td>참여 지문에 필요한 개인정보 항목 일체</td>
            </tr>
            <tr>
              <th>수집ㆍ이용의 목적</th>
              <td>
                1) 대상자 선정 및 통보<br/>
                2) 이벤트 운영<br/>
                3) 상기 1) 내지 2)에 수반된 업무
              </td>
            </tr>
            <tr>
              <th>보유 및 이용기간</th>
              <td>본 이벤트 종료 시</td>
            </tr>
          </table>
        </div>
      <p>위와 같이 개인정보 수집ㆍ이용에 동의합니다.</p>
    </div>
  )
}

export default Policy4;
