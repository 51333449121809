import React from 'react';

const Policy2 = () => {
  return(
    <div className="policy">
        <h2>경신홀딩스 개인정보 처리방침</h2>
        <p>'경신홀딩스'는 정보주체의 자유와 권리 보호를 위해 「개인정보 보호법」 및 관계 법령이 정한 바를 준수하여, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보 보호법」
            제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.
            정보주체의 자유와 회원의 개인정보를 중요시하며, "정보통신망 이용촉진 및 정보보호"에 관한 법률을 준수하고 있습니다.</p>
        <div>
            <h5>1. 개인정보의 처리목적</h5>
            <p>
                경신홀딩스는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 「개인정보 보호법」
                제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
            </p>
            <ul>
                <li>
                    <h5>1) 회원 가입 및 관리</h5>
                    회원 가입의사 확인, 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지 등의 목적으로 개인정보를 처리합니다.
                </li>
                <li>
                    <h5>2) 서비스 제공</h5>
                    경신그룹 창립50주년 이벤트 참여를 위한 서비스 제공 및 콘텐츠 제공으로 개인정보를 처리합니다.
                </li>
            </ul>
        </div>
        <div>
            <h5>2. 처리하는 개인정보 항목 및 보유기간</h5>
            <ul>
                <li>1) 경신홀딩스는 법령에 따른 개인정보 보유·이용기간 또는 정보주체로부터 개인정보를 수집 시에 동의 받은 개인정보 보유·이용기간 내에서 개인정보를 처리· 보유합니다.
                </li>
                <li>2) 개인정보 항목은 다음과 같습니다. <br/>- 근무 회사명, 이름, 사번, 이메일, 연락처</li>
                <li>3) 개인정보 보유기간은 앱 운영 중단 또는 회원 탈퇴시까지입니다.</li>
            </ul>
        </div>
        <div><h5>3. 개인정보 처리의 위탁</h5>
            <ul>
                <li>
                    1) 경신홀딩스는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.
                    <table>
                        <colgroup>
                            <col width={'30%'}/>
                            <col width={'70%'}/>
                        </colgroup>
                        <tr>
                            <th>위탁받는 자(수탁자)</th>
                            <td>주식회사 시하기획</td>
                        </tr>
                        <tr>
                            <th>위탁 업무</th>
                            <td>
                                ① 본 서비스 제공 및 관리<br/>
                                ② 이벤트 진행 및 경품 지급<br/>
                                ③ 상기 ①내지 ② 에 수반한 업무
                            </td>
                        </tr>
                        <tr>
                            <th>보유 및 이용기간</th>
                            <td>위탁 계약 종료시</td>
                        </tr>
                    </table>
                </li>
                <li>2) 경신홀딩스는 위탁계약 체결 시 「개인정보 보호법」 제26조에 따라 위탁업무 수행목적 외 개인정보 처리금지, 기술적·관리적 보호조치, 재위탁 제한, 수탁자에 대한
                    관리·감독, 손해배상 등 책임에 관한 사항을 계약서 등 문서에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.
                </li>
                <li>3) 위탁업무의 내용이나 수탁자가 변경될 경우에는 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다</li>
            </ul>
        </div>
        <div>
            <h5>4. 개인정보의 파기 절차 및 방법</h5>
            <ul>
                <li>1) 경신홀딩스는 개인정보 보유기간의 경과, 처리목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체없이 해당 개인정보를 파기합니다.</li>
                <li>2) 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리목적이 달성되었음 에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를
                    별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다.
                </li>
                <li>
                    3) 개인정보 파기의 절차 및 방법은 다음과 같습니다.
                    <ul>
                        <li>i. 파기절차<br/>경신홀딩스는 파기 사유가 발생한 개인정보를 선정하고, 경신홀딩스의 개인정보 보호책임자의 승인을 받아 개인정보를 파기합니다.</li>
                        <li>ii. 파기방법<br/>경신홀딩스는 전자적 파일 형태로 기록·저장된 개인정보는 기록을 재생할 수 없도록 파기하며, 종이 문서에 기록·저장된 개인정보는 분쇄기로
                            분쇄 하거나 소각하여 파기합니다.
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
        <div>
            <h5>5. 정보주체와 법정대리인의 권리·의무 및 행사방법</h5>
            <ul>
                <li>1) 정보주체는 경신홀딩스에 대해 언제든지 개인정보 열람·정정·삭제·처리정지 요구 등의 권리를 행사할 수 있습니다.</li>
                <li>2) 권리 행사는 경신홀딩스에 대해 「개인정보 보호법」 시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수
                    있으며, {'<'}개인정보처리자명{'>'} 은(는) 이에 대해 지체없이 조치하겠습니다.
                </li>
                <li>3) 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수도 있습니다. 이 경우 “개인정보 처리 방법에 관한 고시(제2020-7호)” 별지
                    제11호 서식에 따른 위임장을 제출하셔야 합니다.
                </li>
                <li>4) 개인정보 열람 및 처리정지 요구는 「개인정보 보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한 될 수 있습니다.</li>
                <li>5) 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.</li>
                <li>6) 경신홀딩스는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.</li>
            </ul>
        </div>
        <div>
            <h5>6. 개인정보 보호책임자 및 개인정보 열람청구</h5>
            <ul>
                <li>1) 경신홀딩스는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임부서를
                    지정하고 있습니다.
                </li>
                <li>2) 정보주체는 「개인정보 보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 경신홀딩스는 정보주체의 개인정보 열람청구가 신속하게 처리되도록
                    노력하겠습니다.

                </li>
                <li>3) 정보주체는 경신홀딩스의 서비스를 이용하시면서 발생한 모든 개인정보보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의할 수
                    있습니다. 경신홀딩스는
                    정보주체의 문의에 대해 지체없이 답변 및 처리해드릴 것입니다.
                    <table>
                        <colgroup>
                            <col width={'30%'}/>
                            <col width={'70%'}/>
                        </colgroup>
                        <tr>
                            <th>부서명</th>
                            <td>인사총무팀</td>
                        </tr>
                        <tr>
                            <th>연락처</th>
                            <td>032-725-9300</td>
                        </tr>
                        <tr>
                            <th>이메일</th>
                            <td>ksholdings@kyungshin.co.kr</td>
                        </tr>
                    </table>
                </li>
            </ul>
        </div>
        <div>
            <h5>7. 개인정보 처리방침의 변경</h5>
            <p>① 이 개인정보 처리방침은 2024.03.19부터 적용됩니다.</p>
        </div>
    </div>
  )
}

export default Policy2;
