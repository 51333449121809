import React from 'react';


const MailModal = ({setOpen, title, content}) => {
  return (
    <div className={'modal-wrapper'}>
      <div className="text-modal">
        <div className="close" onClick={setOpen}>
          <span/>
          <span/>
        </div>
        <p>{title}</p>
        <span>{content}</span>
      </div>
    </div>
  )
}

export default MailModal;
