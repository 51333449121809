import React from "react";
import {Routes, Route, useLocation, Navigate} from "react-router-dom";
import Main from "./page/Main";
import Vision from "./page/Vision";
import Header from "./component/Header";
import Detail from "./page/Detail";
import Policy1 from "./page/Policy1";
import Policy2 from "./page/Policy2";
import Policy3 from "./page/Policy3";
import Policy4 from "./page/Policy4";
import DevDetail from "./page/DevDetail";
import DetailWebview from "./page/DetailWebView";


function App() {
  const params = useLocation();
  return (
      <div className="App">
        {!params.pathname.includes("policy") && !params.pathname.includes("webview") && <Header/>}
        <Routes>
          <Route exact path={"/"} element={<Main/>}/>
          <Route exact path={"/vision"} element={<Vision/>}/>
          <Route exact path={"/detail"} element={<Detail/>}/>
          <Route exact path={"/webview/detail"} element={<DetailWebview/>}/>
          <Route exact path={"/dev-detail"} element={<DevDetail/>}/>
          <Route exact path={"/policy1"} element={<Policy1/>}/>
          <Route exact path={"/policy2"} element={<Policy2/>}/>
          <Route exact path={"/policy3"} element={<Policy3/>}/>
          <Route exact path={"/policy4"} element={<Policy4/>}/>
          <Route path={"*"} element={<Navigate to={'/'} replace={true}/>}/>
        </Routes>
      </div>
  );
}

export default App;
