import React, {useState, useEffect} from 'react'
import {useLocation, useNavigate} from "react-router-dom";
import TextModal from "./modal/textModal";
import {request} from "../apis/http";
import moment from "moment";


const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const today = moment();

  const router = useNavigate();
  const [isMobileOpen, setIsMobileOpen] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [text, setText] = useState();
  const [section1, setSection1] = useState();

  const openAlertModal = (item) => {
    if(item.id === 10) {
      setText({title: "곧 오픈합니다.", content: `곧 공개 됩니다.`});
    } else {
      setText({title: "COMING SOON", content: item.content});
    }
    setIsAlert(!isAlert);
  }

  const goToDetail = (value) => {
    setIsMobileOpen(false);
    if(value.id === "test") {
      navigate(`/dev-detail`);

    } else {
      navigate(`/detail?id=${value.id}`);

    }
  }


  const getData = async () => {
    const {data, status} = await request.get('/board/boardlist/event');
    if (status === 200) {
      // data.data.push(
      //   {
      //     id: 'test',
      //     openDate: '2024-03-18',
      //     title: '경신 50주년 모바일 앱 오픈!',
      //     startPeriod: '2024-03-18',
      //     endPeriod: '2024-03-18',
      //     content: '3월 19일에 공개됩니다.',
      //     thumbnailImg: '/images/open-mobile.png',
      //     preThumbnailImg: '/images/open-mobile.png',
      //   }
      // )
      // data.data.push({
      //   id: 'test2',
      //   openDate:  '2024-03-17',
      //   preThumbnailImg: '/images/event02_blur.png',
      //   title: 'COMING SOON',
      //   content: '3월에 공개됩니다.',
      //   startPeriod: '2024-03-17',
      //   endPeriod:  '2024-03-17',
      // })
      setSection1(data.data);
    }

  }

  useEffect(() => {
    if (!section1) {
      getData();
    }
  }, [section1]);


  if (location.pathname.includes('detail')) {
    return (
      <header className="detail-header">
        <a href={"/"} className="logo">
          <img src="/images/logo.png"/>
        </a>
        <div className={'menu-close'} onClick={() => {
          router('/');
        }}>
          <span/>
          <span/>
        </div>
      </header>
    )
  } else {
    return (
      <header>
        <a href={"/"} className="logo">
          <img src="/images/logo.png"/>
        </a>
        <button className={'menu-btn'} onClick={() => {
          setIsMobileOpen(!isMobileOpen)
        }}>
          <span/>
          <span/>
          <span/>
        </button>
        {isMobileOpen &&
          (<div className={`menu-screen ${isMobileOpen ? 'active' : ''}`}>
            <header>
              <a className="logo" href={'/'}>
                <img src="/images/logo.png" alt="경신그룹 50주년"/>
              </a>
              <div className={'menu-close'} onClick={() => {
                setTimeout(() => {
                  setIsMobileOpen(!isMobileOpen);
                }, 300);
                document.querySelector('.menu-screen').classList.remove('active');
                document.querySelector('.menu-screen').classList.add('close');

              }}>
                <span/>
                <span/>
                {/*<img src="/images/close-btn.png" alt="닫기" />*/}
              </div>
            </header>
            <div className="container">
              <div className="menu-wrapper">
                <div className="menu-item">
                  <a onClick={() => {
                    setIsMobileOpen(false);
                    router('/vision');
                  }} className={location.pathname === '/vision' ? 'active' : undefined}>
                    <h4>돌아보다, 나아가다</h4>
                    <span>함께 일군 50년, 함께 만들 100년</span>
                  </a>
                </div>
                <div className="divide"/>
                <div className="category"><span>PRIDE</span></div>

                {section1 && section1.map((link) => {
                    return (
                      <div className="menu-item">
                        <div key={`slider1-${link.id}`}
                             onClick={link.isOpen ? () => goToDetail(link) : () => openAlertModal(link)}>
                          <a>
                            <h4>{moment(link.openDate).isBefore(today) ? link.title : 'COMING SOON'}</h4>
                            {
                              link.id !== 10 ? moment(link.openDate).isBefore(today) ?
                                <span>{link.startPeriod === link.endPeriod ? moment(link.startPeriod).format('yyyy.MM.DD') : `${moment(link.startPeriod).format('yyyy.MM.DD')} ~ ${moment(link.endPeriod).format('yyyy.MM.DD')}`}</span>
                                : <span>{link.openDate.split('-')[1]}월에 공개 됩니다.</span> : <span/>
                            }
                          </a>
                        </div>
                      </div>
                    )
                  }
                )}
              </div>
            </div>
          </div>)}
        {isAlert && text && <TextModal setOpen={() => setIsAlert(false)} title={text.title} content={text.content}/>}
      </header>
    )
  }
}
export default Header;
